import React from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => {
  return (
    <div style={styles.menuContainer}>
      <NavLink to="/" style={styles.buttonStyle} activeStyle={styles.activeButtonStyle}>Home</NavLink>
      <NavLink to="/family-files" style={styles.buttonStyle} activeStyle={styles.activeButtonStyle}>Family Files</NavLink>
      {/* Add more buttons if needed */}
    </div>
  );
};

const styles = {
  menuContainer: {
    display: 'flex',
    flexDirection: 'row', // Align menu items horizontally
    justifyContent: 'center', // Center menu items
    padding: '20px', // Add some space around the menu
    listStyle: 'none', // Remove list styles
  },
  buttonStyle: {
    margin: '5px', // Space out the buttons
    padding: '10px 20px', // Padding inside the buttons
    textDecoration: 'none', // Remove underline from links
    fontSize: '16px', // Font size for the text inside buttons
    borderRadius: '5px', // Rounded corners for buttons
    border: 'none', // Remove default border
    backgroundColor: '#4CAF50', // Background color for buttons
    color: 'white', // Text color for buttons
    transition: 'all 0.3s ease', // Smooth transition for hover effect
  },
  activeButtonStyle: {
    backgroundColor: '#45a049', // Darker background color for the active link
  }
};

export default Menu;
