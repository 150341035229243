import React, { useState, useEffect } from 'react';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_KEY = process.env.REACT_APP_API_KEY;
const SCOPES = 'https://www.googleapis.com/auth/drive.file';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const FILE_ID = '1RTc3LBNE5w1_wu2OZqekndRNDJ9QnRPEBfXyOqLsPZg'; // Replace with your actual file ID

const FamilyFiles = () => {
  const [fileContent, setFileContent] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => {
      window.gapi.load('client:auth2', initClient);
    };
    document.body.appendChild(script);
  }, []);

  const initClient = () => {
    window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(() => {
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      setIsSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    }).catch(error => {
      console.error("Error during gapi client initialization", error);
    });
  };

  const updateSigninStatus = (isSignedInStatus) => {
    setIsSignedIn(isSignedInStatus);
    if (isSignedInStatus) {
      fetchFileContent();
    }
  };

  const fetchFileContent = () => {
    window.gapi.client.drive.files.get({
      fileId: FILE_ID,
      alt: 'media'
    }).then(response => {
      setFileContent(response.body);
    }).catch(error => console.error("Error fetching file content", error));
  };

  const handleContentChange = (event) => {
    setFileContent(event.target.value);
  };

  const saveFile = () => {
    const accessToken = window.gapi.auth.getToken().access_token; // Get the access token
    const file = new Blob([fileContent], { type: 'text/plain' });
    const metadata = {
      'name': 'Sample File', // Update with the actual name if necessary
      'mimeType': 'text/plain', // Update with the actual mimeType if necessary
    };

    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    fetch(`https://www.googleapis.com/upload/drive/v3/files/${FILE_ID}?uploadType=multipart`, {
      method: 'PATCH',
      headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
      body: form,
    }).then((response) => {
      return response.json();
    }).then((jsonResponse) => {
      console.log("Update Complete", jsonResponse);
      // Handle success - maybe update state or inform user
    }).catch((error) => {
      console.error("Error saving file", error);
      // Handle error - inform the user
    });
  };

  if (!isSignedIn) {
    return <button onClick={() => window.gapi.auth2.getAuthInstance().signIn()}>Sign In</button>;
  }

  return (
    <div>
      <h2>Editing File</h2>
      <textarea value={fileContent} onChange={handleContentChange} style={{ width: '100%', height: '200px' }} />
      <button onClick={saveFile}>Save</button>
    </div>
  );
};

export default FamilyFiles;
